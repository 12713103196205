<template>
    <div class="signUp_page">
        <div class="content flex-center column">
            <div class="title">学一技之长，改一生命运</div>
            <div class="freeReceive">免费领取试学名额</div>
            <div class="formData">
                <div class="list Between">
                    <div class="userInfo">
                        <input type="text" placeholder="姓名" v-model="params.userName">
                    </div>
                    <div class="userInfo">
                        <input maxlength="11" type="text" v-model="params.phoneNumber" placeholder="手机号">
                    </div>
                </div>
                <div class="signUpBtn display pointer" @click="submitBtn">立即报名</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        courseId: {},
        courseType: {},
    },
    data() {
        return {
            params: {
                userName: "",
                phoneNumber: "",
                courseId: "",
                courseType: "",
                courseName: "",
                email: "",
                companyName: "",
                questionContent: "",
            },
        }
    },
    created() {

    },
    mounted() { },
    methods: {
        // 报名
        submitBtn() {
            if (this.courseType == 1) {
                this.params.courseName = '注册类执业资格课程'
            } else if (this.courseType == 2) {
                this.params.courseName = '施工现场专业管理人员课程'
            } else if (this.courseType == 3) {
                this.params.courseName = '建筑工程施工课程'
            } else if (this.courseType == 4) {
                this.params.courseName = '装饰装修类课程'
            } else if (this.courseType == 5) {
                this.params.courseName = '专项安全培训课程'
            } else if (this.courseType == 6) {
                this.params.courseName = '特种作业课程'
            }
            this.params.courseId = this.courseId
            this.params.courseType = this.courseType
            if (this.params.userName == '') {
                return this.$message.error('请输入您的姓名')
            }
            if (this.params.courseId == '') {
                return this.$message.error('请选择课程')
            }
            if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
                return this.$message.error('请正确输入手机号')
            }
            this.api.addCourseApply(this.params).then(res => {
                if (res.code == 0) {
                    this.$message.success('成功！')
                    this.params = {
                        userName: "",
                        phoneNumber: "",
                        courseId: "",
                    }
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.signUp_page {
    min-width: 1200px;
    height: 518px;
    background: url("../assets/img/course/bg.png") no-repeat;
    background-size: 100% 100%;

    .title {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #F5F5F5;
        margin-top: 105px
    }

    .freeReceive {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FCC800;
        margin-top: 22px
    }

    .formData {
        width: 671px;
        margin-top: 38px;

        .list {
            .userInfo {
                width: 323px;
                height: 54px;
                background: #FFFFFF;

                input {
                    width: 300px;
                    height: 54px;
                    padding-left: 22px;
                    font-size: 16px;
                }
            }
        }

        .signUpBtn {
            width: 671px;
            height: 54px;
            background: #FCC800;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-top: 26px;
        }
    }
}
</style>