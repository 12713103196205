<template>
    <div class="lecturer_pages">
        <div class="carousel_box">
            <el-carousel :interval="5000">
                <el-carousel-item v-for="(item, index) in lecturerList" :key="index">
                    <div class="el_carousel_cont flex">
                        <div class="el_carousel_cont_fl">
                            <img :src="item.recommendAvatar" alt="">
                        </div>
                        <div class="el_carousel_cont_fr">
                            <div class="lecturer_name">{{ item.lecturerName }}
                                <div class="line"></div>
                            </div>
                            <div class="lecturer_details">

                                {{ item.introduction }}</div>
                            <div class="learn_more display pointer" @click="service">了解更多</div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>

    </div>
</template>
<script>
export default {
    props: {
        lecturerList: {}
    },
    data() {
        return {}
    },
    mounted() { },
    methods: {
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    }
}
</script>
<style lang="scss" scoped>
.lecturer_pages {
    width: 1400px;
    margin: 0 auto;

    .carousel_box {
        margin-top: 51px;

    }

    ::v-deep.el-carousel__container {
        height: 378px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    ::v-deep.el-carousel {
        .el-carousel__item {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .el-carousel__container {
            .el-carousel__arrow {
                width: 48px;
                height: 48px;
                font-size: 24px;
                background: rgba(51, 51, 51, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }
    }

    .el_carousel_cont {
        width: 1200px;
        height: 378px;

        .el_carousel_cont_fl {
            img {
                width: 486px;
                height: 377px;
                margin-right: 78px;
            }
        }

        .el_carousel_cont_fr {
            padding-top: 36px;

            .lecturer_name {
                font-size: 28px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #003C7E;
                line-height: 28px;
                padding-bottom: 14px;
                position: relative;

                .line {
                    width: 44px;
                    height: 2px;
                    background: #FF881E;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }

            .lecturer_details {
                width: 470px;
                height: 145px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 30px;
                margin-top: 18px;
            }

            .learn_more {
                width: 167px;
                height: 47px;
                background: #FF881E;
                border-radius: 5px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                margin-top: 40px;
            }
        }
    }
}
</style>