<template>
    <div class="safety_page">
        <div class="banner">
            <img class="bannerImg" :src="formData.carouselImgUrl" alt="">
        </div>
        <div class="module1">
            <div class="content">
                <div class="title">{{ formData.safetyTrainingName }}</div>
                <div class="num">{{ formData.safetyTrainingIntroduce }}</div>
                <img class="bgImg display" :src="formData.safetyTrainingImgUrl" alt="">
            </div>
        </div>
        <div class="module2">
            <div class="content">
                <div class="title">{{ formData.securityIncidentName }}</div>
                <div class="safetyAccidents flex flex-wrap">
                    <div class="safetyAccidents_list" v-for="(item,index) in formData.courseTwoSecurityIncidentVOS"
                        :key="index">
                        <img class="safetyAccidents_list_img" :src="item.incidentImgUrl" alt="">
                        <div class="safetyAccidents_list_div display">
                            {{ item.incidentName }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module3">
            <div class="title">{{ formData.safetyTrainingIndustryName }}</div>
            <div class="main">
                <el-carousel :autoplay="false">
                    <el-carousel-item class="el-car-item"
                        v-for="(list, index) in formData.courseTwoSafetyTrainingIndustryVOS" :key="index">
                        <div class="safetyTrainingData flex-center column" v-for="(item,index2) in list" :key="index2">
                            <img class="icon_img" :src="item.safetyTrainingIndustryImgUrl" alt="" />
                            <div class="name">{{item.safetyTrainingIndustryName}}</div>
                            <div class="line"></div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="module4">
            <div class="content">
                <div class="title">{{ formData.securityServiceProcessName }}</div>
                <div class="process_box Around">
                    <div class="process_list display column"
                        v-for="(item,index) in formData.courseTwoSecurityServiceProcessVOS" :key="index">
                        <img class="process_list_img" :src="item.icon" alt="">
                        <div class="process_list_name">{{ item.securityServiceProcessName }}</div>
                        <div class="process_list_introduce">{{ item.securityServiceProcessIntroduce }}</div>
                        <img class="process_list_jt" src="@/assets/img/safety/jt.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="module5">
            <div class="title">{{ formData.packageTrainingName }}</div>
            <div class="main">
                <el-carousel :autoplay="false">
                    <el-carousel-item class="el-car-item" v-for="(list, index) in formData.courseTwoPackageTrainingVOS"
                        :key="index">
                        <div class="safetyTrainingData flex-center column" v-for="(item,index2) in list" :key="index2">
                            <img class="icon_img" :src="item.packageTrainingImgUrl" alt="" />
                            <div class="name">{{ item.packageTrainingName }}</div>
                            <div class="introduce">{{ item.packageTrainingIntroduce }}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="module6">
            <div class="title">一线金牌名师，教学质量保障</div>
            <div class="content">
                <Lecturer :lecturerList="formData.teacherVOS"></Lecturer>
            </div>
        </div>
        <div class="module7">
            <div class="title">科学防范体系，有效化解安全风险</div>
            <div class="content display">
                <img class="icon_bg" :src="formData.securityRiskImgUrl" alt="" />
            </div>
        </div>
        <div class="module8">
            <SignUp v-if="courseId" :courseType="2" :courseId="courseId"></SignUp>
        </div>
    </div>
</template>
<script>
import Banner from '@/components/banner'
import Lecturer from '@/components/lecturer'
import SignUp from '@/components/signUp'
export default {
    components: {
        Banner,
        Lecturer,
        SignUp
    },
    data(){
        return{
            safetyTraining:[],
            params:{
                typeWorkId:""
            },
            formData:{},
            courseId:"",
        }
    },
    // 监听路由变化
    watch: {
        $route: "urlName",
    },
    mounted(){
        this.safetyTraining = this.init(this.safetyTraining,4)
        this.params.typeWorkId = this.$route.query.id
        this.getData() 
    },
    methods:{
        // 监听url参数变化
        urlName() {
            this.safetyTraining = this.init(this.safetyTraining, 4)
            this.params.typeWorkId = this.$route.query.id
            this.getData();
        },
        //初始化
        getData(){
            this.api.findCourseTwoData(this.params).then(res=>{
                this.formData = res.data
                this.formData.courseTwoSafetyTrainingIndustryVOS = this.init(this.formData.courseTwoSafetyTrainingIndustryVOS, 4)
                this.formData.courseTwoPackageTrainingVOS = this.init(this.formData.courseTwoPackageTrainingVOS, 4)
                this.courseId = res.data.id
            })
        },
         // 轮播图格式化
        init(dataList, num) {
            let newDataList = []
            let current = 0
            if (dataList && dataList.length > 0) {
                for (let i = 0; i <= dataList.length - 1; i++) {
                    if (i % num !== 0 || i === 0) {
                        if (!newDataList[current]) {
                            newDataList.push([dataList[i]])
                        } else {
                            newDataList[current].push(dataList[i])
                        }
                    } else {
                        current++
                        newDataList.push([dataList[i]])
                    }
                }

            }
            return dataList = [...newDataList]
        },
    }
}
</script>

<style lang="scss" scoped>



    .safety_page{
        .bannerImg {
            width: 100%;
        }
        .title{
            font-size: 32px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            padding-top: 82px;
            text-align: center;
        }
        .module1{
            background: #fcf9fc;
            padding-bottom: 61px;
            .num{
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
                margin-top: 18px;
                text-align: center;
            }
            .bgImg{
                width: 880px;
                height: 530px;
                margin: 0 auto;
                margin-top: 36px;
                
            }
        }
        .module2{
            padding-bottom: 82px;
            .safetyAccidents{
                margin-top: 40px;
                .safetyAccidents_list:nth-child(3n+1){
                     margin-left:0 !important;
                }
                .safetyAccidents_list{
                    border: 1px solid #fff;
                    margin-left: 40px;
                    margin-bottom: 18px;
                    .safetyAccidents_list_img{
                        width: 370px;
                        height: 250px;
                    }
                    .safetyAccidents_list_div{
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        margin: 18px 0;
                    }
                }
            }
        }
        .module3{
            height: 670px;
            background: #fafafa;
            .main{
                width: 1400px;
                margin: 0 auto;
                margin-top: 44px;
                .safetyTrainingData{
                    width: 280px;
                    height: 420px;
                    box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.2500);
                    padding: 2px ;
                    box-sizing: border-box;
                    margin-right: 26px;
                    .icon_img{
                        width: 100%;
                        height: 258px;
                    }
                    .name{
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        line-height: 20px;
                        color: #333333;
                        margin-top: 72px;
                    }
                    .line{
                        width: 73px;
                        height: 2px;
                        background: #FF792B;
                        margin-top: 22px;
                    }
                }
                ::v-deep.el-car-item {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding-left: 106px;
                }
                    ::v-deep.el-carousel {
                        .el-carousel__container {
                            height: 430px;
                            display: flex;
                            .el-carousel__arrow {
                                width: 48px;
                                height: 48px;
                                font-size: 24px;
                                background: rgba(51, 51, 51, 0.5);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                    
                        }
                    }
                    
                    ::v-deep.el-carousel__indicators {
                        display: none;
                    }
            }
        }
        .module4{
            height: 510px;
            .process_box{
                margin-top: 50px;
                .process_list:nth-child(1){
                    .process_list_jt{
                        display: none;
                    }
                }
                .process_list{
                    position: relative;
                    .process_list_jt{
                        width: 112px;
                        height: 12px;
                        position: absolute;
                        top: 47px;
                        right: 150px;
                    }
                    .process_list_img{
                        width: 98px;
                        height: 98px;
                        background: #003C7E;
                        border-radius: 50%;
                    }
                    
                    .process_list_name{
                        font-size: 20px;
                        color: #003C7E;
                        margin-top: 30px;
                    }
                    .process_list_introduce {
                        width: 135px;
                        font-size: 14px;
                        color: #333333;
                        line-height: 24px;
                        margin-top: 15px;
                        text-align: center;
                    }
                }
            }

        }
        .module5{
            background: #fafafa;
            height: 600px;
            .main {
                    width: 1400px;
                    margin: 0 auto;
                    margin-top: 45px;
            
                    .safetyTrainingData {
                        width: 280px;
                        height: 334px;
                        box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.2500);
                        margin-right: 26px;
                        
                        
                        .icon_img {
                            width: 100%;
                            height: 200px;
                        }
            
                        .name {
                            font-size: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            line-height: 20px;
                            margin-top: 26px;
                            padding: 0px 20px;
                            box-sizing: border-box;
                        }
            
                        .introduce {
                            width: 238px;
                            height: 37px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            line-height: 20px;
                            margin-top: 5px;
                        }
                    }
            
                    ::v-deep.el-car-item {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        padding-left: 106px;
                    }
            
                    ::v-deep.el-carousel {
                        .el-carousel__container {
                            height: 340px;
                            display: flex;
            
                            .el-carousel__arrow {
                                width:48px;
                                height:48px;
                                font-size: 24px;
                                background: rgba(51, 51, 51, 0.5);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
            
                        }
                    }
                    ::v-deep.el-carousel__indicators {
                        display: none;
                    }
                }
        }
        .module6{
            height: 640px;
        }
        .module7{
            height: 890px;
            background: #fafafa;
            .icon_bg{
                width: 880px;
                height: 600px;
                margin-top: 84px;
            }
        }
    }
</style>